<template>
    <el-container class="home-container">
        <!--    导航-->
        <el-header>

            <div style="width:100%">
                <div style="margin-left: 20px; font-size: 25px; color: #FFF">
                    <div style="float: right;margin-right: 3%; font-size: 20px; color: #FFF">
                        当前登录: {{ loginUser }}
                    </div>
                    <div>现金贷管理后台</div>
                </div>
            </div>

            <el-dropdown>
                <div class="block">
                    <el-avatar :size="50" :src="img" style="cursor: pointer"></el-avatar>
                </div>
                <el-dropdown-menu slot="dropdown" trigger="click">
                     <el-dropdown-item>
                         <span type="danger"  @click="toPassword"><span class="el-icon-house"></span> &nbsp;修改密码</span>
                     </el-dropdown-item>
                     <div @click="logout">
                         <el-dropdown-item>
                            <span type="danger">
                                <span class="el-icon-switch-button"></span>
                                &nbsp;退出登录
                            </span>
                         </el-dropdown-item>
                     </div>
                </el-dropdown-menu>
            </el-dropdown>
        </el-header>

        <!--主体-->
        <el-container style="height: 500px">
            <!--菜单-->
            <el-aside :width="isOpen === true ? '64px' : '200px'">
                <div class="toggle-btn" @click="toggleMenu">|||</div>
                <el-menu
                        class="el-menu-vertical-demo"
                        :collapse="isOpen"
                        :router="true"
                        :default-active="activePath"
                        background-color="#001529"
                        :collapse-transition="false"
                        text-color="rgba(255,255,255,0.7)"
                        unique-opened>
                    <MenuTree :menuList="this.menuList"></MenuTree>
                </el-menu>
            </el-aside>
            <!--右边主体-->
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MenuTree from "../components/MenuTree"; //引进菜单模板

    export default {
        data() {
            return {
                isAdmin: false,
                passWordDialog: false,
                activePath: "", //激活的路径
                isOpen: false,
                formLabelWidth: "125px",
                menuList: [
                  {
                    "id": 4,
                    "parentId": 0,
                    "menuName": "进入人工审核",
                    "url": "/finance/examineList",
                    "icon": "el-icon-document-checked",
                    "orderNum": 10,
                    "open": 0,
                    "disabled": false,
                    "perms": "examineList",
                    "type": 0,
                    "children": []
                  },
                  {
                    "id": 1,
                    "parentId": 0,
                    "menuName": "系统管理",
                    "url": null,
                    "icon": "el-icon-s-tools",
                    "orderNum": 10,
                    "open": 1,
                    "disabled": false,
                    "perms": null,
                    "type": 0,
                    "children": [
                      {
                        "id": 3,
                        "parentId": 1,
                        "menuName": "用户管理",
                        "url": "/system/users",
                        "icon": "el-icon-user",
                        "orderNum": 2,
                        "open": 0,
                        "disabled": false,
                        "perms": "users",
                        "type": 0,
                        "children": []
                      },
                      {
                        "id": 4,
                        "parentId": 1,
                        "menuName": "催收用户",
                        "url": "/system/collector",
                        "icon": "el-icon-user-solid",
                        "orderNum": 3,
                        "open": 0,
                        "disabled": false,
                        "perms": "collector",
                        "type": 0,
                        "children": []
                      },
                    ]
                  }
                  ],
                userInfo: {},
                img: require("../assets/131724.png"),
                static: "",
                loginUser: "",
                passWordMsg: {},
                menuType: false,
            };
        },
        components: {
            MenuTree,
        },
        methods: {

            /**
             *
             * 退出登入
             */
            async logout() {
                const res = await this.$confirm("此操作将退出系统, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出登入",
                    });
                });
                if (res === "confirm") {
                  const {data: res} = await this.$http.get("/system/auth/logout");
                    LocalStorage.clearAll();
                    await this.$router.push("/login");
                }
            },

            /**
             * 去修改密码
             */
            toPassword() {
                this.$router.push("/system/password");
            },


            /**
             获取用户信息
             */
            async getUserInfo() {
              this.userInfo = LocalStorage.get("USER");
              this.loginUser = this.userInfo.username;
            },

            /**
             * 菜单伸缩
             */
            toggleMenu() {
                this.isOpen = !this.isOpen;
            },

            /**
             *
             * 关闭密码框
             */
            closePassWordDialog() {
                this.$refs.passWordRef.clearValidate();
                this.passWordMsg = {};
            },

        },
        mounted() {
            this.getUserInfo();
        },
    };
</script>

<style>
    /* 为对应的路由跳转时设置动画效果 */

    .el-header {
        background-color: #001529;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 19px;

        padding-left: 0px;
    }

    .el-aside {
        background-color: #001529;
    }

    .el-main {
        background-color: #eaedf1;
    }

    .home-container {
        width: 100%;
        height: 100% !important;
    }

    .toggle-btn {
        background-color: #2d8cf0 !important;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }

    .update-input {
        width: 300px;
    }
</style>
